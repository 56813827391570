import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/how-to-make-ajax-calls-in-jquery",
  "date": "2014-02-28",
  "title": "HOW TO MAKE AJAX CALLS IN JQUERY",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "There are more than one way to make AJAX calls in jQuery. In this tutorial, let’s compare these methods as well as inspect the AJAX calls with Firebug."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`What is AJAX`}</h2>
    <p>{`AJAX stands for Asynchronous JavaScript and XML. The request generated from AJAX is called an XHR, which is shorthand for XML HTTP request is same as AJAX.
With AJAX, JavaScript grabs new content from the server and makes changes to the current page throughout the lifetime of the page, no refresh or redirection is needed.`}</p>
    <h2>{`Caching AJAX`}</h2>
    <p>{`The AJAX request content can either be static (like images) or dynamic (data). For static content, we may want the response cached. For dynamic content, which can change in a second's time, caching AJAX becomes a problem. Internet Explorer always caches AJAX calls, while other browsers behave differently. So we tell the browser explicitly whether or not AJAX should be cached. With jQuery, we can accomplish this simply by typing:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`$.ajaxSetup ({
   cache: false
});
`}</code></pre>
    <h3>{`1. load(): Load HTML From a Remote URL and Inject it into the DOM`}</h3>
    <p>{`The most common use of AJAX is for loading HTML from a remote location and injecting it into the DOM, with jQuery's `}<inlineCode parentName="p">{`load()`}</inlineCode>{` function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`$.ajaxSetup ({
   cache: false
});
var ajax_loading_img = "<img src='img/loading.gif' alt='loading...' />";
//  load() functions
var loadUrl = "ajax/load.html";
$("#load_basic").click(function(){
    $("#result").html(ajax_loading_img).load(loadUrl);
});
`}</code></pre>
    <ul>
      <li parentName="ul">{`$.ajaxSetup instructs the browser NOT to cache AJAX calls. (This is important for IE)`}</li>
      <li parentName="ul">{`After the button is clicked, it takes a little while before the new HTML is loaded. During the loading time, it's better to show an animation (loading.gif)  ensure that the page is currently loading. The `}<inlineCode parentName="li">{`ajax_loading_img`}</inlineCode>{` variable contains the HTML tag of the loading sign.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ajax/load.html`}</inlineCode>{` is the url from which the HTML is retrieved.`}</li>
      <li parentName="ul">{`When the button is clicked, it makes an AJAX call to the url, receives the response HTML, and injects it into the DOM. The syntax is simply $("#DOM").load(url).`}</li>
    </ul>
    <p>{`In Firebug: Click  Net  > XHR > + > Params - Here's all parameters passed through the GET method. The long number string passed under a "`}<em parentName="p">{`" key, is how jQuery makes sure the request is not cached.                              
Every request has a different "`}</em>{`" parameter, so browsers consider each of them to be unique.`}</p>
    <p>{`Click `}<inlineCode parentName="p">{`Response`}</inlineCode>{` tab. Here's the HTML response returned from the remote url. `}</p>
    <h2>{`Load Part of the Remote File`}</h2>
    <p>{`With `}<inlineCode parentName="p">{`load(url + "#DOM")`}</inlineCode>{`, only the contents within #DOM are injected into current page.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`$("#load_dom").click(function(){
   $("#result").html(ajax_loading_img).load(loadUrl + " #picture");
});
`}</code></pre>
    <h2>{`Pass Parameters Through the GET Method`}</h2>
    <p>{`By passing a string as the second param of load(), these parameters are passed to the remote url in the GET method`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`$("#load_get").click(function(){
    $("#result").html(ajax_loading_img).load(loadUrl, "language=php&version=5");
});
`}</code></pre>
    <h2>{`Pass Parameters Through the POST Method`}</h2>
    <p>{`If parameters are `}<strong parentName="p">{`passed as an object`}</strong>{` (rather than string), they are passed to the remote url in the POST method.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`$("#load_post").click(function(){
    $("#result").html(ajax_loading_img)
    .load(loadUrl, {language: "php", version: 5});
});
`}</code></pre>
    <h2>{`Do Something on AJAX Success`}</h2>
    <p>{`A function can be passed to load() as a callback. This function will be executed as soon as the AJAX request is completed successfully.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`$("#load_callback").click(function(){
   $("#result").html(ajax_loading_img).load(loadUrl, null, function(responseText){
      alert("Response:\\n" + responseText);
    });
});
`}</code></pre>
    <h2>{`2. $.getJSON(): Retrieve JSON from a Remote Location`}</h2>
    <p>{`JSON (JavaScript Object Notation) is a lightweight data-interchange format. It's very convenient when exchanging data programmatically with JSON.`}</p>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`$.getJSON( "ajax/test.json", function( data ) {
  var items = [];
  $.each( data, function( key, val ) {
    items.push( "<li id='" + key + "'>" + val + "</li>" );
  });
  $( "<ul/>", {
    "class": "my-new-list",
       html: items.join( "" )
    }).appendTo( "body" );
});
`}</code></pre>
    <p>{`This is the equivalent to Ajax:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`$.ajax({
   dataType: "json",
   url: url,
   data: data,
   success: success
});
`}</code></pre>
    <p>{`Few facts about getJSON:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`$.getJSON`}</inlineCode>{` doesn’t load information directly to the DOM. So the function is $.getJSON, NOT `}<inlineCode parentName="li">{`$(“#result”).getJSON`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`$.getJSON`}</inlineCode>{` accepts three parameters. A url, parameters passed to the url and a callback function`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`$.getJSON`}</inlineCode>{` passes parameters in GET method. POSTing is not possible with $.getJSON`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`$.getJSON`}</inlineCode>{` treats response as JSON`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`$.getJSON`}</inlineCode>{`’s function name is NOT camel-cased. All four letters of “JSON” are in uppercase`}</li>
    </ul>
    <h2>{`3. $.getScript(): Load JavaScript from a Remote Location`}</h2>
    <p>{`We can load JavaScript files with `}<inlineCode parentName="p">{`$.getScript`}</inlineCode>{` method.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`//  $.getScript()
var scriptUrl = "ajax/script.php";
 
$("#getScript").click(function(){
     $("#result").html(ajax_loading_img);
     $.getScript(scriptUrl, function(){
       $("#result").html("");
     });
});
`}</code></pre>
    <ul>
      <li parentName="ul">{`$.getScript accepts only two parameters, a url, and a callback function.`}</li>
      <li parentName="ul">{`Neither the GET nor POST params can be passed to $.getScript. (Of course you can append GET params to the url.)`}</li>
      <li parentName="ul">{`JavaScript files don't have to contain the ".js" extension. In this case, the remote url points to a PHP file.`}</li>
    </ul>
    <h2>{`4. $.get(): Make GET Requests`}</h2>
    <p><inlineCode parentName="p">{`$.get()`}</inlineCode>{` is a more general-purpose way to make GET requests. It handles the response of many formats including xml, html, text, script, json, and jonsp. `}</p>
    <p>{`Click on the `}<inlineCode parentName="p">{`$.get()`}</inlineCode>{` button in the `}<a parentName="p" {...{
        "href": "http://cdn.tutsplus.com/net/uploads/legacy/412_ajaxCalls/DEMO/index.htm"
      }}>{`demo page`}</a>{` and see the code.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`$("#get").click(function(){
   $("#result").html(ajax_loading_img);
   $.get(
        loadUrl,
        {language: "php", version: 5},
        function(responseText){
            $("#result").html(responseText);
        },
        "html"
    );
});
`}</code></pre>
    <ul>
      <li parentName="ul">{`$.get() is completely different, as compared to get(). The latter has nothing to do with AJAX at all.`}</li>
      <li parentName="ul">{`$.get accepts the response type as the last parameter, which makes it more powerful than the first functions we introduced today. Specify response type if it's not html/text. Possible values are xml, html, text, script, json and jonsp.`}</li>
    </ul>
    <h2>{`5. $.post(): Make POST Requests`}</h2>
    <p>{`$.post() is a more general-purpose way to make POST requests. It does exactly the same job as $.get(), except for the fact that it makes a POST request instead.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`$("#post").click(function(){
    $("#result").html(ajax_load);
    $.post(
        loadUrl,
        {language: "php", version: 5},
        function(responseText){
        $("#result").html(responseText);
        },
        "html"
    );
});
`}</code></pre>
    <p>{`The use of $.post() is the same as its brother, $.get(). Check the POST request in Firebug (shown in the following image).`}</p>
    <h2>{`6. $.ajax():`}</h2>
    <p>{`Up to this point, we've examined five commonly used jQuery AJAX functions. They bear different names but, behind the scenes, they generally do the exact same job with slightly different configurations. If you need maximum control over your requests, check out the $.ajax() function.
This is jQuery's low-level AJAX implementation. See $.get, $.post etc. for higher-level abstractions that are often easier to understand and use, but don't offer as much functionality (such as error callbacks). -jQuery's official Documentation
In my opinion, the first five functions should satisfy most of our needs. But if you need to execute a function on AJAX error, $.ajax() is your only choice.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`In summary, we took an in-depth look of five ways to make AJAX calls with jQuery.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`load()`}</strong>{`: Load a piece of html into a container DOM.`}</li>
      <li parentName="ul"><strong parentName="li">{`$.getJSON()`}</strong>{`: Load a JSON with GET method.`}</li>
      <li parentName="ul"><strong parentName="li">{`$.getScript()`}</strong>{`: Load a JavaScript.`}</li>
      <li parentName="ul"><strong parentName="li">{`$.get()`}</strong>{`: Use this if you want to make a GET call and play extensively with the response.`}</li>
      <li parentName="ul"><strong parentName="li">{`$.post()`}</strong>{`: Use this if you want to make a POST call and don't want to load the response to some container DOM.`}</li>
      <li parentName="ul"><strong parentName="li">{`$.ajax()`}</strong>{`: Use this if you need to do something when XHR fails, or you need to specify ajax options (e.g. cache: true) on the fly.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      